import { useState } from 'react';
import PropTypes from 'prop-types';
import { useSwipeable } from 'react-swipeable';
import Button from '@mui/material/Button';
import ClearIcon from '@mui/icons-material/Clear';
import MemoryActions from '../MemoryActions/MemoryActions';
import ShowVideo from 'components/UI/ShowVideo/ShowVideo';
import DeleteMemory from '../DeleteMemory/DeleteMemory';
import classes from './Memory.module.css';

const Memory = props => {
  const handlers = useSwipeable({
    onSwipedLeft: () => props.nextMemoryHandler(),
    onSwipedRight: () => props.prevMemoryHandler()
  });

  const [openFullImage, setOpenFullImage] = useState(false);
  const [hideMemoryId, setHideMemoryId] = useState(0);

  const hideMemoryHandler = id => {
    setHideMemoryId(id);
  };

  const prevMemory = () => {
    props.prevMemoryHandler();
  };

  const nextMemory = () => {
    props.nextMemoryHandler();
  };

  const showImageHandler = () => {
    setOpenFullImage(!openFullImage);
  };

  return (
    <>
      <div {...handlers}>
        <section
          className={
            hideMemoryId === props.dataMemory.memoryId
              ? classes.memoryNoShow
              : classes.memorySection
          }
        >
          {(props.dataMemory.isOwnerMemory ||
            props.dataMemory.isOwnerTribut) && (
            <DeleteMemory
              idMemory={props.dataMemory.memoryId}
              deleteMemoryHandler={props.deleteMemoryHandler}
              hideMemory={hideMemoryHandler}
            />
          )}

          {props.dataMemory.mediaType === 'png' ||
          props.dataMemory.mediaType === 'jpeg' ||
          props.dataMemory.mediaType === 'jpg' ? (
            <div className={classes.memoryContainerB}>
              <div className={classes.memoryContainerImage}>
                <figure
                  key={props.dataMemory.memoryId}
                  className={classes.avatarMemory}
                  id={`memoryImage-${props.dataMemory.memoryId}`}
                >
                  <img
                    alt={props.dataMemory.memoryId}
                    className={classes.imgMemory}
                    src={props.dataMemory.url}
                  />
                </figure>
              </div>
            </div>
          ) : props.dataMemory.mediaType === 'mp4' &&
            props.dataMemory.thumbnail === null ? (
            <figure
              key={props.dataMemory.memoryId}
              className={classes.avatarMemory}
              id={`memoryImage-${props.dataMemory.memoryId}`}
            >
              <img
                alt={props.dataMemory.memoryId}
                className={classes.imgMemory}
                src="https://d3cohyhv4rpctw.cloudfront.net/videoProceso.jpg"
              />
            </figure>
          ) : (
            <ShowVideo
              srcVideo={props.dataMemory.url}
              memoryView={props.memoryView}
              memoryId={props.dataMemory.memoryId}
              memoryPlaying={props.memoryPlaying}
              pauseVideo={props.pauseVideo}
              thumbnail={props.dataMemory.thumbnail}
              dataMemory={props.dataMemory}
              memory
            />
          )}

          <p className={classes.memoryOwnerName}>
            {props.dataMemory.firstName} {props.dataMemory.lastName}
          </p>

          {props.dataMemory.dateTimeOriginal && (
            <p className={classes.memoryOwnerDate}>
              {props.dataMemory.dateTimeOriginal}
            </p>
          )}
        </section>
      </div>
      {!props.onlyMemory && (
        <div className={classes.btnsCarouselContainer}>
          <Button
            className={classes.btnCarrousel}
            onClick={prevMemory}
          >
            <figure className={classes.arrowIcon}>
              <img
                className={classes.img}
                src="https://d1g6c01s9ssd7h.cloudfront.net/img/icon_Back_1.png"
              />
            </figure>
          </Button>
          {(props.dataMemory.mediaType === 'png' ||
            props.dataMemory.mediaType === 'jpeg' ||
            props.dataMemory.mediaType === 'jpg') && (
            <Button
              className={classes.btnCarrousel}
              onClick={showImageHandler}
            >
              <figure className={classes.arrowIcon}>
                <img
                  className={classes.img}
                  src="https://d1g6c01s9ssd7h.cloudfront.net/img/icon_fullScreen.png"
                />
              </figure>
            </Button>
          )}
          <Button
            className={classes.btnCarrousel}
            onClick={nextMemory}
          >
            <figure className={classes.arrowIcon}>
              <img
                className={classes.img}
                src="https://d1g6c01s9ssd7h.cloudfront.net/img/icon_Next_1.png"
              />
            </figure>
          </Button>
        </div>
      )}

      <MemoryActions
        tributId={props.tributId}
        memory
        dataMemory={props.dataMemory}
        hideMemoryId={hideMemoryId}
        loadData={props.loadData}
        memoryView={props.memoryView}
      />
      {openFullImage && (
        <div className={classes.fullImageContainer}>
          <Button
            onClick={showImageHandler}
            className={classes.closeImageBtn}
          >
            <ClearIcon />
          </Button>
          <figure
            key={props.dataMemory.memoryId}
            className={classes.avatarMemoryFull}
            id={`memoryImage-${props.dataMemory.memoryId}`}
          >
            <img
              alt={props.dataMemory.memoryId}
              className={classes.imgMemory}
              src={props.dataMemory.url}
            />
          </figure>
        </div>
      )}
    </>
  );
};

Memory.propTypes = {
  // eslint-disable-next-line react/require-default-props
  tributId: PropTypes.number,
  // eslint-disable-next-line react/require-default-props
  dataMemory: PropTypes.object,
  // eslint-disable-next-line react/require-default-props
  loadData: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  memoryView: PropTypes.number,
  // eslint-disable-next-line react/require-default-props
  memoryPlayingHandler: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  memoryPlaying: PropTypes.number,
  // eslint-disable-next-line react/require-default-props
  pauseVideo: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  prevMemoryHandler: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  nextMemoryHandler: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  onlyMemory: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  deleteMemoryHandler: PropTypes.func
};

export default Memory;
