import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import videojs from 'video.js';

import 'videojs-mobile-ui/dist/videojs-mobile-ui.css';
import 'videojs-mobile-ui';

import classes from './VideoInfo.module.css';

const VideoInfo = props => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const { options, onReady } = props;

  useEffect(() => {
    // Make sure Video.js player is only initialized once

    if (!playerRef.current) {
      const videoElement = videoRef.current;
      if (!videoElement) return;
      const player = (playerRef.current = videojs(
        videoElement,
        options,
        () => {
          onReady && onReady(player);
        }
      ));

      player.mobileUi();
    } else {
      const videoElement = videoRef.current;
      const player = (playerRef.current = videojs(
        videoElement,
        options,
        () => {
          onReady && onReady(player);
        }
      ));

      player.mobileUi();
      if (props.visibilityVideo) {
        player.play();
      }
    }
  }, [options, videoRef]);

  useEffect(() => {
    const player = playerRef.current;
    return () => {
      if (player) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div style={{ height: 368 }}>
      <div data-vjs-player>
        {props.dataMemory.mediaType === 'ACC' && (
          <figure className={classes.imgAudio}>
            <img
              src={props.dataMemory.thumbnail}
              className={classes.img}
            />
          </figure>
        )}
        <video
          style={{ height: '100%', width: '100%' }}
          ref={videoRef}
          className="video-js vjs-big-play-centered"
          // eslint-disable-next-line react/no-unknown-property
          playsinline="playsinline"
          // eslint-disable-next-line react/no-unknown-property
          webkit-playsinline
        />
      </div>
    </div>
  );
};

VideoInfo.propTypes = {
  // eslint-disable-next-line react/require-default-props
  openTrailer: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  dataMemory: PropTypes.object,
  // eslint-disable-next-line react/require-default-props
  onClose: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  trailerData: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  options: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  onReady: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  memoryId: PropTypes.number,
  // eslint-disable-next-line react/require-default-props
  memoryPlaying: PropTypes.number,
  // eslint-disable-next-line react/require-default-props
  pauseVideo: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  visibilityVideo: PropTypes.bool
};

export default VideoInfo;
