import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ClearIcon from '@mui/icons-material/Clear';

import UserButton from '../UserButton/UserButton';

import classes from './DialogMessage.module.css';

const DialogMessage = props => {
  return (
    <Dialog onClose={props.closeDialog} open={props.open}>
      {props.title && (
        <DialogTitle
          className={
            props.delete || props.video
              ? classes.titleDelete
              : classes.title
          }
        >
          {props.title}
        </DialogTitle>
      )}
      {!props.video && (
        <Button
          className={classes.closeModalBtn}
          onClick={props.closeDialog}
        >
          <ClearIcon className={classes.closeModalIcon} />
        </Button>
      )}
      <DialogContent>
        <DialogContentText
          className={
            props.delete
              ? classes.messageTextDelete
              : props.video
              ? classes.messageTextVideo
              : classes.messageText
          }
        >
          {!props.mortuaryMessage ? (
            props.message
          ) : (
            <div
              className={classes.mortuatyInfo}
              dangerouslySetInnerHTML={{ __html: props.message }}
            />
          )}
        </DialogContentText>
      </DialogContent>
      {!props.mortuaryMessage && !props.delete && !props.video && (
        <UserButton
          onClickHandler={props.closeDialog}
          text="De acuerdo"
        />
      )}
      {props.delete && (
        <div className={classes.btnsContainer}>
          <UserButton
            double
            cancel
            onClickHandler={props.closeDialog}
            text="Cancelar"
          />
          <UserButton
            double
            onClickHandler={props.action}
            text="Eliminar"
          />
        </div>
      )}
      {props.video && (
        <div className={classes.progressContainer}>
          <figure className={classes.barProgress}>
            <img
              alt="progress bar"
              className={classes.img}
              src="https://d3cohyhv4rpctw.cloudfront.net/line_mensaje.png"
            />
          </figure>
        </div>
      )}
    </Dialog>
  );
};

DialogMessage.propTypes = {
  // eslint-disable-next-line react/require-default-props
  open: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  message: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  closeDialog: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  title: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  mortuaryMessage: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  delete: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  action: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  video: PropTypes.bool
};

export default DialogMessage;
