import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import MemoriesCollage from '../MemoriesCollage/MemoriesCollage';
import AddMemory from 'components/tribut/AddMemory/AddMemory';
import UploadFiles from '../UploadFiles/UploadFiles';
import Loading from 'components/UI/Loading/Loading';

import { getUserData, isLogged } from 'store/user-actions';
import { getMemoryList } from 'store/memory-actions';

import classes from './MemoriesCollageContainer.module.css';

const MemoriesCollageContainer = ({
  tributId,
  tributDetails,
  memoryIdHandler,
  changeView,
  memoriesDataHandler
}) => {
  const loged = isLogged();
  const [userData, setUserData] = useState(null);
  const [acceptFiles, setAcceptFiles] = useState(null);
  const [lstMemory, setLstMemory] = useState(null);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const dataMemories = useSelector(state => state.memoriesData);

  useEffect(() => {
    if (dataMemories.data.length === 0) {
      dispatch(getMemoryList(tributId));
    }
  }, [dispatch]);

  useEffect(() => {
    if (dataMemories.data === 'error') {
      setLoading(false);
      setLstMemory([]);
      memoriesDataHandler([]);
    }
    if (
      dataMemories.data.length > 0 &&
      dataMemories.data !== 'error'
    ) {
      setLstMemory(dataMemories.data);
      memoriesDataHandler(dataMemories.data);
      setLoading(false);
    }
  }, [dataMemories.data]);

  useEffect(() => {
    if (loged) {
      (async () => {
        let userInfo = await getUserData();
        setUserData(userInfo.user);
      })();
    }
  }, [loged]);

  const handleOpenUploadModal = typeFile => {
    setAcceptFiles(typeFile);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    // (async () => {
    //   let _lstMemory = await getMemoryList(tributId);
    //   if (_lstMemory) {
    //     setLstMemory(_lstMemory);
    //     memoriesDataHandler(_lstMemory);
    //   }
    // })();
  };

  const handleSeeMemory = memoryId => () => {
    memoryIdHandler(memoryId);
    changeView('specific');
  };

  return (
    <div className={classes.memory}>
      <p className={classes.invitationText}>
        Comparte tus Memorias de {tributDetails.firstName}{' '}
        {tributDetails.lastName}
      </p>
      {tributId != 1 && (
        <UploadFiles
          userData={userData}
          openUploadModal={handleOpenUploadModal}
          changeView={changeView}
        />
      )}
      {loading ? (
        <Loading />
      ) : lstMemory !== null && lstMemory.length > 0 ? (
        ''
      ) : (
        <p className={classes.addMemoryText}>
          Sé el primero en enviar tus Memorias
        </p>
      )}
      <AddMemory
        tributId={tributId}
        open={open}
        handleClose={handleClose}
        userData={userData}
        acceptFiles={acceptFiles}
      />
      {lstMemory !== null && lstMemory.length > 0 && (
        <MemoriesCollage
          seeMemory={handleSeeMemory}
          lstMemory={lstMemory}
          tributId={tributId}
        />
      )}
    </div>
  );
};

MemoriesCollageContainer.propTypes = {
  // eslint-disable-next-line react/require-default-props
  tributId: PropTypes.number,
  // eslint-disable-next-line react/require-default-props
  tributDetails: PropTypes.object,
  // eslint-disable-next-line react/require-default-props
  changeView: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  memoriesDataHandler: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  memoryIdHandler: PropTypes.func
};

export default MemoriesCollageContainer;
