// import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import classes from './MemoriesCarrousel.module.css';

const MemoriesCarrousel = props => {
  console.log('🚀 ~ MemoriesCarrousel ~ props:', props);
  return (
    <Carousel
      showThumbs={false}
      showStatus={false}
      infiniteLoop={true}
      className={classes.carrouselContainer}
    >
      {props.data.map((item, index) => (
        <div key={index}>
          <img
            src="https://d3cohyhv4rpctw.cloudfront.net/videoG.jpg"
            className={classes.imageContainer}
          />
        </div>
      ))}
    </Carousel>
  );
};

MemoriesCarrousel.propTypes = {
  // eslint-disable-next-line react/require-default-props
  data: PropTypes.object
};

export default MemoriesCarrousel;
