import { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import DialogMessage from 'components/UI/DialogMessage/DialogMessage';

import { deleteMemory } from 'store/memory-actions';

import classes from './DeleteMemory.module.css';

const DeleteMemory = props => {
  const [openDialog, setOpenDialog] = useState(false);
  const [message] = useState(
    'La Memoria se borrará de manera permanente junto con todos sus comentarios, imágenes y videos relacionados. Esta acción no se puede deshacer.'
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const openMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteMemoryModal = () => {
    setOpenDialog(true);
    handleClose(null);
  };

  const deleteMemoryHandler = () => {
    (async () => {
      let deleteMemoryData = await deleteMemory(props.idMemory);
      if (deleteMemoryData) {
        props.loadData();
        props.hideMemory(props.idMemory);
        setOpenDialog(false);
      }
    })();
  };

  const closeDialogHandler = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <Button className={classes.deleteMemoryBtn} onClick={openMenu}>
        <DeleteOutlinedIcon className={classes.icon} />
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={deleteMemoryModal}>Eliminar</MenuItem>
      </Menu>
      <DialogMessage
        closeDialog={closeDialogHandler}
        action={deleteMemoryHandler}
        open={openDialog}
        message={message}
        title="Eliminar Memoria"
        delete
      />
    </>
  );
};

DeleteMemory.propTypes = {
  // eslint-disable-next-line react/require-default-props
  idMemory: PropTypes.number,
  // eslint-disable-next-line react/require-default-props
  loadData: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  hideMemory: PropTypes.func
};

export default DeleteMemory;
