import { Fragment } from 'react';
import PropTypes from 'prop-types';

import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import IconButton from '@mui/material/IconButton';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import MovieOutlinedIcon from '@mui/icons-material/MovieOutlined';
import AddToPhotosOutlinedIcon from '@mui/icons-material/AddToPhotosOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';

import Dropzone from 'react-dropzone';

import classes from './DropZoneComponent.module.css';

const DropZoneComponent = props => {
  console.log('props.acceptFiles', props.acceptFiles);
  return (
    <Dropzone
      className={
        props.condolenceImage || props.condolenceVideo
          ? classes.dropZoneCondolenceContainer
          : props.memory
          ? classes.dropZoneMemoryContainer
          : props.createTribut
          ? classes.dropZoneAvatarContainer
          : classes.dropZoneContainer
      }
      onDrop={props.onDrop}
      accept={props.acceptFiles}
      multiple={props.multipleFiles}
      maxSize={props.maxSizeFile}
    >
      {props.condolenceImage ? (
        <IconButton className={classes.iconBtn}>
          <ImageOutlinedIcon className={classes.icon} />
        </IconButton>
      ) : props.condolenceVideo ? (
        <IconButton className={classes.iconBtn}>
          <MovieOutlinedIcon className={classes.icon} />
        </IconButton>
      ) : props.type === 'image' ? (
        <aside
          className={
            props.image ===
            'https://d3cohyhv4rpctw.cloudfront.net/icon_imagen.png'
              ? classes.imageContainer
              : props.createTribut
              ? classes.imageAvatarContainer
              : classes.myImageContainer
          }
        >
          {props.image ? (
            <figure className={classes.avatarDropZone}>
              <img
                alt="m"
                src={props.image}
                className={
                  props.createTribut
                    ? classes.imgAvatar
                    : classes.img
                }
              />
            </figure>
          ) : (
            <div className={classes.noUserContainer}>
              <PersonOutlinedIcon className={classes.noUserIcon} />
            </div>
          )}

          {!props.condolence && (
            <Fragment>
              <AddPhotoAlternateOutlinedIcon
                className={classes.addImageIcon}
              />
              <p className={classes.addImageText}>Subir imagen</p>
            </Fragment>
          )}
        </aside>
      ) : props.type === 'video' ? (
        <aside
          className={
            props.extension === null
              ? classes.imageContainer
              : classes.videoContainer
          }
        >
          {props.extension === null ? (
            <figure className={classes.avatarDropZone}>
              <img
                alt="m"
                src={props.image}
                className={classes.img}
              />
            </figure>
          ) : (
            <video
              controls
              src={props.image}
              className={classes.avatarDropZone}
            />
          )}
        </aside>
      ) : (
        <aside>
          {props.multimedias.length > 0 ? (
            props.multimedias.map((item, key) => (
              <Fragment key={key}>
                {item.includes('image') ? (
                  <figure className={classes.avatarDropZone}>
                    <img
                      alt="m"
                      className={classes.img}
                      src={item}
                    />
                  </figure>
                ) : (
                  <video
                    autoPlay={false}
                    className={classes.img}
                    muted
                    src={item}
                  />
                )}
              </Fragment>
            ))
          ) : (
            <div className={classes.memoriesUploadContainer}>
              <AddToPhotosOutlinedIcon
                className={classes.memoriesIcon}
              />
              <p className={classes.memoriesUploadText}>
                {props.acceptFiles === 'image/*,audio/*,video/*'
                  ? 'Selecciona fotos/videos'
                  : 'Selecciona fotos/videos'}
                <br />
                <span className={classes.memoriesUploadSubtext}>
                  o arrastra y suelta
                </span>
              </p>
            </div>
          )}
        </aside>
      )}
    </Dropzone>
  );
};

DropZoneComponent.propTypes = {
  // eslint-disable-next-line react/require-default-props
  multimedias: PropTypes.object,
  // eslint-disable-next-line react/require-default-props
  image: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  extension: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  type: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  onDrop: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  acceptFiles: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  multipleFiles: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  maxSizeFile: PropTypes.number,
  // eslint-disable-next-line react/require-default-props
  condolence: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  memory: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  createTribut: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  condolenceVideo: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  condolenceImage: PropTypes.bool
};

export default DropZoneComponent;
